<template>
  <base-loader
    v-if="loading"
  />
  <cmc-page
    v-else
    :title="organization.name"
    :breadcrumb="breadcrumb"
    :primaryTabs="primaryTabs"
    :secondaryTabs="secondaryTabs"
  >
    <cmc-card
      :with-collapse="false"
    >
      <router-view
        :administrableOrgs="administrableOrgs"
        :organization="organization"
        @addToBreadcrumb="breadcrumbToAdd = $event"
        @deleted="returnToList"
        @refreshOrg="fetchOrganization"
      />
    </cmc-card>
  </cmc-page>
</template>
<script>
import apis from '@/utils/apis';
import authz from '@/authz';
import CmcPage from '@/components/nextgen/display/CmcPage';
import { orgSwitchNavigationMixin } from '@/mixins/orgSwitchNavigationGuard';
import { organizationFeatures } from '@/mixins/organizationFeatures';
import { mapGetters } from 'vuex';
import { debounceAsync } from '@/utils';

export default {
  name: 'OrganizationPage',
  components: { CmcPage },
  mixins: [orgSwitchNavigationMixin, organizationFeatures],
  beforeRouteLeave(to, from, next) {
    if (!this.hasOtherOrgAccess
          && !to.path.includes(this.myOrganization.id)
          && to.path.includes('organizations')
          && this.loggedIn) {
      return next({ path: `/admin/organizations/${this.myOrganization.id}` });
    }
    return next();
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isInvoiceVisible: false,
      organization: {
        name: null,
        isBillable: false,
      },
      administrableOrgs: {},
      breadcrumbToAdd: [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters([
      'selectedOrganization',
      'hasOtherOrgAccess',
      'myOrganization',
      'loggedIn',
    ]),
    primaryTabs() {
      const tabs = [
        {
          key: 'organizationDetails',
          label: 'details',
          withI18n: true,
          to: { name: 'organizationDetails', params: { id: this.organization.id } },
        },
        ...(this.showVerifiedDomainTabs ? [{
          key: 'verifiedDomains',
          label: 'verified_domains',
          withI18n: true,
          to: { name: 'verifiedDomains', params: { id: this.organization.id } },
        }] : []),
        ...(this.showSecurityTabs ? [{
          key: 'organizationSecurity',
          label: 'authentication_policies',
          withI18n: true,
          to: { name: 'organizationSecurity', params: { id: this.organization.id } },
        }] : []),
        {
          key: 'services',
          label: 'assigned_services',
          withI18n: true,
          to: { name: 'organizationServices', params: { id: this.organization.id } },
        },
        ...(this.showAssignedQuotas ? [{
          key: 'assigned_quotas',
          label: 'quotas.assigned_quotas',
          withI18n: true,
          to: { name: 'quotas-assigned' },
        }] : []),
        ...(this.showBillableTabs ? [{
          key: 'organizationBilling',
          label: 'billing',
          withI18n: true,
          to: { name: 'org-billing' },
        }] : [])
      ];
      return tabs;
    },
    secondaryTabs() {
      const tabs = [
        ...(this.showSecurityTabs ? [
          {
            parentKey: 'organizationSecurity',
            item: {
              key: 'nativeConfig',
              label: 'native_tab',
              withI18n: true,
              to: { name: 'nativeConfig', params: { id: this.organization.id } },
            },
          },
          {
            parentKey: 'organizationSecurity',
            item: {
              key: 'ldapConfig',
              label: 'ldap_tab',
              withI18n: true,
              to: { name: 'ldapConfig', params: { id: this.organization.id } },
            },
          },
          {
            parentKey: 'organizationSecurity',
            item: {
              key: 'oidc',
              label: 'oidc_tab',
              withI18n: true,
              to: { name: 'autoAccountCreationConfig', params: { id: this.organization.id } },
            },
          }
        ] : []),
        ...(this.showBillableTabs ? [
          {
            parentKey: 'organizationBilling',
            item: {
              key: 'billing-overview',
              label: 'overview',
              withI18n: true,
              to: { name: 'billing-overview' },
            },
          },
          {
            parentKey: 'organizationBilling',
            item: {
              key: 'org-billing-details',
              label: 'billing_preferences',
              withI18n: true,
              to: { name: 'org-billing-details' },
            },
          },
        ] : []),
      ];
      return tabs;
    },
    breadcrumb() {
      const crumbs = !this.id ? null : [
        {
          label: 'organizations',
          withI18n: true,
          path: '/admin/organizations', 
        },
        {
          label: this.organization?.name,
          withI18n: false,
        },
      ].filter(b => !(b.label === 'organizations' && !this.hasOtherOrgAccess));
      return [...crumbs, ...this.breadcrumbToAdd];
    },
    showBillableTabs() {
      return this.isInvoiceVisible && this.featuresAccess.MONETIZATION && this.organization.isBillable && !this.organization.isReseller;
    },
    showVerifiedDomainTabs() {
      return authz.hasPermission('reseller:authentication');
    },
    showSecurityTabs() {
      return authz.hasPermission('reseller:orgs');
    },
    showAssignedQuotas() {
      return authz.hasPermission('admin:readQuotas');
    },
    orgLabel() {
      if (this.hasOtherOrgAccess) {
        return 'organizations';
      }
      return 'organization';
    },
  },
  async created() {
    if (this.id) {
      await this.fetchAdministrableOrg();
      await this.fetchOrganization();
    }
    this.fetchOrganization = debounceAsync(this.fetchOrganization, 500);
  },
  methods: {
    async fetchOrganization() {
      const orgResp = await apis.organizations.find(this.id);
      this.organization = orgResp.data;
      this.fetchActivity(this.organization.id);
      this.fetchUsers(this.organization.id);
      await this.fetchInvoiceVisibility(this.organization.id);
      this.loading = false;
    },
    async fetchActivity(id) {
      const activityResp = await apis.organizations.activity(id);
      this.organization = { ...this.organization, activity: activityResp.data[id] };
    },
    async fetchUsers(id) {
      const userResp = await apis.users.list({ qs: { organization_id: id } });
      this.organization = { ...this.organization, users: userResp.data };
    },
    async fetchInvoiceVisibility(id) {
      const invoiceVisibleResp = await apis.organizations.getInvoiceVisibility(id);
      this.isInvoiceVisible = invoiceVisibleResp?.data;
    },
    async fetchAdministrableOrg() {
      const administrableOrgResp = await apis.organizations.administrableOrgs(this.id);
      if (administrableOrgResp.ok) {
        this.administrableOrgs = administrableOrgResp.data;
      }
    },
    returnToList() {
      this.$router.push({ path: '/admin/organizations' });
    },
  },
};
</script>
