import utils from '../httputils';
import { CrudApi } from './api';

class OrganizationApi extends CrudApi {
  constructor() {
    super('organizations');
  }

  findRoles(orgId) {
    return this.get(`${this.path}/${orgId}/roles`);
  }

  findServiceConnections(orgId, includeBackendOrgInfos = true) {
    const qs = {
      include_backend_org_infos: includeBackendOrgInfos
    };
    return this.get(`${this.path}/${orgId}/manageable_connections`, { qs });
  }

  validateEntryPoint(entryPointToValidate) {
    const qs = {
      entry: entryPointToValidate,
    };
    return this.get(`${this.path}/entryPoint`, { qs });
  }

  validateIsInvoiceable(orgId) {
    return this.get(`organizations/${orgId}/invoiceable`);
  }

  getInvoiceVisibility(orgId) {
    return this.get(`organizations/${orgId}/invoice_visibility`);
  }

  listRoleBindings(orgId, qs = {}) {
    return this.get(`organizations/${orgId}/role_bindings`, { qs });
  }

  assignablePrimaryRoles(orgId) {
    return this.get(`organizations/${orgId}/assignable_primary_roles`);
  }

  administrableUsers(orgId, userId) {
    return this.get(`organizations/${orgId}/administrable_users`, {
      qs: { user_id: userId },
    });
  }

  administrableOrgs(orgId) {
    return this.get('organizations/administrable_orgs', { qs: { id: orgId } });
  }

  activity(id = '') {
    return this.get('/organizations/activity', { qs: { organization_id: id } });
  }

  activityRange(startDate, endDate) {
    return this.get('/organizations/activityRange', { qs: { start_date: startDate, end_date: endDate } });
  }

  getVerifiedDomains(orgId) {
    return this.get(`/organizations/${orgId}/verified_domains`);
  }

  getVerifiedDomain(orgId, domainId) {
    return this.get(`/organizations/${orgId}/verified_domains/${domainId}`);
  }

  getCustomFields(orgId) {
    return this.get(`/custom_fields/organization/${orgId}`);
  }

  createVerifiedDomain(orgId, url) {
    return this.post(`/organizations/${orgId}/verified_domains`, {
      body: `{"domain": "${url}"}`,
    });
  }

  deleteVerifiedDomain(orgId, domainId) {
    return this.del(`/organizations/${orgId}/verified_domains/${domainId}`);
  }

  markReseller(orgId) {
    return this.post(`/organizations/${orgId}/mark_reseller`);
  }

  async getPasswordPolicy(orgId) {
    return this.get(`/organizations/${orgId}/password_policy`);
  }

  async deletePasswordPolicy(orgId) {
    return this.del(`/organizations/${orgId}/password_policy`);
  }

  async getSecuritySettings(orgId) {
    return this.get(`/organizations/${orgId}/security_settings`);
  }

  getIdentityProviders(orgId) {
    return this.get(`/organizations/${orgId}/identity_providers`);
  }

  getConnectionAssignmentParameters(orgId, connectionId) {
    return this.get(`/organizations/${orgId}/assignment_parameters_elements`, { qs: { connection_id: connectionId } });
  }

  getBackendOrganizations(orgId, connectionId) {
    return this.get(`/organizations/${orgId}/manageable_connections/${connectionId}/backend_organizations`);
  }

  updateSecuritySettings(orgId, securitySettings) {
    return this.put(`/organizations/${orgId}/security_settings`, {
      headers: { [utils.CONTENT_TYPE_HEADER]: utils.APPLICATION_JSON },
      body: JSON.stringify(securitySettings),
    });
  }

  async setBillable(orgId, billableOrgInfo) {
    return this.put(`/organizations/${orgId}/billable`, {
      headers: { [utils.CONTENT_TYPE_HEADER]: utils.APPLICATION_JSON },
      body: JSON.stringify(billableOrgInfo),
    });
  }

  getBillingInfo(orgId) {
    return this.get(`/organizations/${orgId}/billing_info`);
  }

  getBillingProviderType(orgId) {
    return this.get(`/organizations/${orgId}/billing_provider_type`);
  }

  getEnabledFeature() {
    return this.get('/organizations/enabled_features');
  }

  getOrgListWithFeatures() {
    return this.get('/organizations/features');
  }

  getOrgListWithServiceConnections(includeDeletedConnections = false) {
    return this.get('/organizations/service_connections', { qs: { include_deleted_connections: includeDeletedConnections } });
  }

  getBillingEmail(orgId) {
    return this.get(`/organizations/${orgId}/billing_emails`);
  }

  fetchApplicablePricingPackages(orgId) {
    return this.get(`/organizations/${orgId}/pricing_packages`);
  }
  

  addDiscount(orgId, discount) {
    return this.post(`/organizations/${orgId}/discounts`, {
      headers: { [utils.CONTENT_TYPE_HEADER]: utils.APPLICATION_JSON },
      body: JSON.stringify(discount),
    });
  }

  editDiscount(orgId, discountId, body) {
    return this.put(`organizations/${orgId}/discounts/${discountId}`, { body });
  }

  assignConnection(orgId, body) {
    return this.post(`organizations/${orgId}/assign_connection`, { body });
  }

  getDiscount(orgId, discountId) {
    return this.get(`organizations/${orgId}/discounts/${discountId}`);
  }

  deactivateDiscount(orgId, discountId) {
    return this.put(`organizations/${orgId}/discounts/${discountId}/deactivate`);
  }

  deleteDiscount(orgId, discountId) {
    return this.del(`organizations/${orgId}/discounts/${discountId}`);
  }

  fetchPackagesForBillingCycle(orgId, billingCycleId) {
    return this.get(`/organizations/${orgId}/billing_cycles/${billingCycleId}/pricing_packages`);
  }

  validateSubOrganizationsExist(orgId) {
    return this.get(`/organizations/${orgId}/exists/suborgs`);
  }

  updateBillingEmails(orgId, emailList) {
    return this.put(`/organizations/${orgId}/billing_emails`, {
      headers: { [utils.CONTENT_TYPE_HEADER]: utils.APPLICATION_JSON },
      body: JSON.stringify(emailList),
    });
  }

  getBillingAddress(orgId) {
    return this.get(`/organizations/${orgId}/billing_address`);
  }

  updateBillingAddress(orgId, body) {
    return this.put(`/organizations/${orgId}/billing_address`, { body });
  }

  getManagedServiceConnections(orgId) {
    return this.get(`/organizations/${orgId}/managed_connections`);
  }
}

const organizationApi = new OrganizationApi();

export default organizationApi;
